<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>财务管理</template>
        <template v-slot:secondMenu>对账管理</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        已对账列表
      </div>


      <div class="radioBox">

        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="购液企业">
            <el-select v-model="formInline.region" placeholder="请选择" @change="onSubmit" clearable>
              <el-option v-for="item in tradList" :key="item.stationId" :label="item.stationName" :value="item.stationId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-radio-group v-model="radio1" @change="agreeChange">
          <el-radio-button label="0">全部</el-radio-button>
          <el-radio-button label="1">未核对</el-radio-button>
          <el-radio-button label="2">已核对</el-radio-button>
        </el-radio-group>
      </div>
      <!-- table表格区域 -->
      <el-table :data="goodsList" border
        :header-cell-style="{ color: '#848484', fontSize: '14px', height: '22px', background: '#f7f7f7' }"
        :cell-style="{ color: '#0a0b1a' }">
        <el-table-column label="购液企业名称" prop="emptionName"></el-table-column>
        <el-table-column label="对账单标题">
          <template slot-scope="scope">
            {{ scope.row.reconciliationName }}({{ scope.row.startDate.substring(0, 10) }}~{{ scope.row.endDate.substring(0, 10) }})
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createDate" width="170px">
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column label="对账车数（车）" prop="countCar"></el-table-column>
        <el-table-column label="合计装车量（吨）" prop="shipmentWeight"></el-table-column>
        <el-table-column label="合计结算量（吨）" prop="totalWeight"></el-table-column>
        <el-table-column label="合计结算金额（元）" prop="totalAmount"></el-table-column>
        <el-table-column label="已开票金额（元）" prop="invoicedAmount"></el-table-column>
        <el-table-column label="气站核对清况" prop="reconciliationStatus">
          <template slot-scope="scope">
            {{ scope.row.reconciliationStatus == 1 ? '未确认' : scope.row.reconciliationStatus == 2 ? '已核对' : '' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.reconciliationStatus == 1" type="text" @click="editDetails(scope.row)">编辑</el-button>
            <trade-remove v-if="scope.row.reconciliationStatus == 1" :role-id='scope.row.reconciliationId'
              @role-list="updateRoleList" />
            <el-button type="text" @click="lookDetails(scope.row, scope.row.reconciliationId)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="float: right;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from "../../../common/BreadcrumbNav";

import {
  getFinancialListOneRequest, emptionListRequest
} from "network/financial";
import TradeRemove from './TradeRemove.vue';


export default {
  name: "List",
  components: {
    BreadcrumbNav,
    TradeRemove
  },
  data () {
    return {
      queryInfo: {
        pageNo: 1,
        pageSize: 10
      },
      formInline: {
        region: ''
      },
      goodsList: [],
      total: 0,
      tradList: [],
      ruleForm: {},
      radio1: 0
    }
  },
  created () {
    this.getGoodsList();
    this.getTardList()
  },
  methods: {
    agreeChange(val) {
            console.log(val);
            this.queryInfo.pageNo = 1
            this.queryInfo.pageSize = 10
            this.getGoodsList()

        },
    onSubmit () {
      this.getGoodsList()
    },
    getTardList () {
      emptionListRequest({
        tardId: localStorage.getItem('enterpriseId')
      }).then(res => {
        let result = res.data
        if (result.code !== 200) {

        }
        this.tradList = result.data
      })
    },
    getGoodsList () {
      getFinancialListOneRequest({
        page: this.queryInfo,
        emptionId: this.formInline.region,
        reconciliationStatus: this.radio1==0?'':this.radio1
      }).then(res => {
        let result = res.data;
        if (result.code !== 200) {
          return this.alertMessage(result.msg, 'error');
        }
        this.goodsList = result.data.list;
        this.total = result.data.total;
      });
    },
    lookDetails (scope, id) {
      this.$router.replace({ path: '/pages/biz/gas_factory/finance/reconciliation/tradeDetails/look', query: { scope: scope, id: id } })
    },

    // 每页显示的数据条数发送变化
    handleSizeChange (newSize) {
      this.queryInfo.pageNo = 1
      this.queryInfo.pageSize = newSize;
      this.getGoodsList();
    },

    // 当前页码发生变化
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage;
      this.getGoodsList();
    },

    updateRoleList () {
      this.getGoodsList();
      this.$emit('goods-list');
    },
    editDetails (scope) {

      this.ruleForm = {
        time: [scope.startDate.substring(0, 10), scope.endDate.substring(0, 10)],
        title: scope.reconciliationName,
        stationId: scope.emptionId,
        reconciliationId: scope.reconciliationId,
        name: scope.emptionName,
        balance: scope.balance
      }
      this.$router.replace({ path: '/pages/biz/gas_factory/finance/reconciliation/editTradeDetails', query: { scope: this.ruleForm, } })
    },

    // 跳转到添加商品的界面
    goAddPage () {
      this.$router.push('/add');
    }
  }
}
</script>

<style scoped>
.radioBox {
  margin-top: 30px;
  margin-bottom: 15px;
}

.el-button--text {
  color: var(--theme_bg_color);
}

.el-button:focus,
.el-button:hover {
  border: 0px;
  border-color: #fff;
  color: var(--theme_bg_color);
}

.el-button--primary {
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color);
}

.el-button--primary:focus,
.el-button--primary:hover {
  border-color: var(--theme_bg_color);
  color: #fff;
}
</style>

